import * as React from "react";
import FeaturedImage from "../components/Featured_Image_Aktuell";
import parse from "html-react-parser";
import * as Constants from "../lib/constants";

const Aktuell = (props) => {
    let posts = props.posts;
    let target;
    return (
        <div id="page">
            <div className="container-fluid">
                {posts.map(function (item) {
                    item.acf.tab ? target = '_blank' : target = '_self';
                    let myurl = Constants.MEDIA_API_URL + '/' + item.featured_media;
                    return (
                        <div className="row my-5 d-non d-sm-bloc">
                            <div
                                className="col-10 offset-1 col-sm-4 offset-sm-1 col-md-3 offset-md-3 order-1 order-sm-0">


                                {item.acf.verlinkung
                                    ? <a href={item.acf.link} target={target}><FeaturedImage url={myurl}/></a>
                                    : <FeaturedImage url={myurl}/>
                                }
                            </div>
                            <div className="col-10 offset-1 col-sm-6 offset-sm-0 col-md-4 offset-md-1 order-0 order-sm-1">
                                <h1>
                                    {parse(item.title.rendered)}
                                </h1>
                                <p>
                                    {parse(item.content.rendered)}
                                    {
                                        item.acf.verlinkung
                                            ? <a href={item.acf.link} target={target}>{item.acf.infozeile}</a>
                                            : <></>
                                    }

                                </p>
                            </div>
                        </div>
                    );

                })}
            </div>
        </div>
    );
};

export default Aktuell;
