import * as React from 'react';
import Startkachel from "../components_low/Startkachel";

const Startkacheln = (props) => {
    let kacheln = props.kacheln
    // console.log(kacheln);
    try {
        return (
            <div id="page">
                <div className="container-fluid startcontainer">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 text-center">
                        {kacheln.map((item) => (
                            // console.log(item);
                            <>
                                {
                                    <Startkachel kachel={item} />
                                }
                            </>
                        ))
                        }
                    </div>
                </div>
            </div>
        )
    } catch {
        return (
            <></>
        )
    }
}
export default Startkacheln;

