import * as React from 'react';
import {useLocation, useRouteMatch} from "react-router-dom";
import Werkliste from "../components_low/Werkliste";
import Werk from "../components_low/Werk";
import {API_WERKE} from "../lib/constants";
import axios from "axios";

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'DATA_FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case 'DATA_FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'DATA_FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            throw new Error();
    }
};

const Werke = () => {
    const [url] = React.useState(API_WERKE);
    const [data, dispatchData] = React.useReducer(
        dataReducer,
        {data: [], isLoading: false, isError: false}
    );


    const handleFetchData = React.useCallback(() => {
        if (localStorage.getItem('werke') != null) {
            dispatchData({
                type: 'DATA_FETCH_SUCCESS',
                payload: JSON.parse(localStorage.getItem('werke')),
            });
        } else {
            dispatchData({type: 'DATA_FETCH_INIT'});
            axios
                .get(url)
                .then((result) => {
                    dispatchData({
                        type: 'DATA_FETCH_SUCCESS',
                        payload: result.data,
                    });
                    localStorage.setItem('werke', JSON.stringify(result.data));

                })
                .catch(() =>
                    dispatchData({type: 'DATA_FETCH_FAILURE'})
                );
        }

    }, [url]);

    React.useEffect(() => {
        handleFetchData();
    }, [handleFetchData]);

    let slug = useLocation().pathname;

    if (useRouteMatch().isExact) {
        return (
            <Werkliste list={data.data}/>
        );
    } else {
        let werk;
        data.data.map(function (item) {
            if (slug === '/werke/' + item.slug + '/') {
                werk = item;
            }
        });

        return (
            <Werk werk={werk}/>
        )
    }
}
export default Werke;
