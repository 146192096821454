import * as React from "react";
import parse from 'html-react-parser';


const TextWidthHeadline = (props) => {

    try {
        // console.log(props);
        return (
            <>
                <h1>
                    {props.props.headline}
                </h1>
                <p className="mb-5">
                    {
                        parse(props.props.textblock)
                    }
                </p>
            </>
        );
    } catch {
        return (
            <></>
        );
    }

};

export default TextWidthHeadline;
