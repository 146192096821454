import * as React from "react";
import parse from "html-react-parser";
import BuchBildGross from "./BuchBildGross";


const Buch = (props) => {
    let buch = props.buch;
    const [bildgross, setBildgross] = React.useState('');
    const [bildmax, setBildmax] = React.useState('');
    const handleChange = (event) => {
        setBildgross(event.target.currentSrc);
        // console.log(event.target.dataset.big);
        setBildmax(event.target.dataset.big);
    };

    try {
        let tmp;
        return (
            <div id="page" className="buchsingle">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-6 text-end order-2 order-sm-1">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 order-2 ">
                                        {
                                            buch.acf.fotos.map(function (item) {
                                                console.log(item);
                                                if(tmp==null) {
                                                    tmp = item.sizes.singleansicht;
                                                }
                                                return (
                                                    // <img className="buch-single-foto" src={item.sizes.singleansicht} data-big={item.url} onClick={handleChange} alt=""/>
                                                    <img className="buch-single-foto" src={item.sizes.singleansicht} data-big={item.sizes.singleansicht} onClick={handleChange} alt=""/>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className="col-12 mb-5 order-1 d-none d-sm-block">
                                        <BuchBildGross url={bildgross} fallback={tmp} bildmax={bildmax} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-10 offset-1 col-sm-5 offset-sm-0  text-left order-1 order-sm-2">
                            <h1>
                                {parse(buch.title.rendered)}
                            </h1>
                            <p>
                                {parse(buch.acf.infoblock)}
                            </p>
                            <p>
                                {parse(buch.acf.auszug)}
                            </p>
                            {parse(buch.content.rendered)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }catch{
        return (
            <></>
        );
    }


};

export default Buch;
