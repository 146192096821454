import * as React from 'react';
import '../components/Featured_Image';
import parse from 'html-react-parser';
// import FeaturedImage from "../components/Featured_Image";
import * as Constants from "../lib/constants";
import TextWidthHeadline from "./TextWidthHeadline";
import FeaturedImageTwo from "../components/Featured_ImageTwo";

const Vitalow = (props) => {
    let content = props.content;

    try {
        return (
            <div id="page" className="vita">
                <div className="container-fluid">

                    <div className="row ">
                        <div className="col-10 col-6 offset-1 col-md-5 offset-md-1 col-xl-3 offset-xl-3 ">
                            <FeaturedImageTwo url={Constants.MEDIA_API_URL + '/' + content.featured_media}/>
                            <p className="pt-5">{parse(content.acf.biographie)}</p>
                            <h1>KONTAKT</h1>
                            <p>{parse(content.acf.kontakt)}</p>
                            <h1>VERTRETEN DURCH</h1>
                            <p>{parse(content.acf.galerievertretung)}</p>
                        </div>
                        <div className="col-10 offset-1 col-md-6 offset-md-0 col-lg-5 col-xl-5 d-none ">

                            <h1>Auszeichnungen / Nominierungen</h1>
                            {
                                content.acf.auszeichnungen.map((item) => (
                                    <p>
                                        <b>{parse(item.jahr)}</b>
                                        <br/>
                                        {parse(item.text)}
                                    </p>
                                ))
                            }
                            <h1>Ausstellungen</h1>
                            {
                                content.acf.einzelausstellungen.map((item) => (
                                    <p>
                                        <b>{parse(item.jahr)}</b>
                                        <br/>
                                        {parse(item.text)}
                                    </p>
                                ))
                            }
                        </div>
                        <div className="col-10 offset-1 col-md-6 offset-md-0 col-lg-5 col-xl-5 ">
                            {
                                content.acf.spalte_rechts.map((item) => (
                                    <TextWidthHeadline props={item} />
                                ))
                            }

                        </div>
                    </div>
                </div>
            </div>

        );
    } catch {
        return (
            <></>
        );
    }
};

export default Vitalow;
