function nl2br (str, isXhtml) {
    if (typeof str === 'undefined' || str === null) {
        return ''
    }

    const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br ' + '/>' : '<br>'
    return (str + '')
        .replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1')
}

export default nl2br;
