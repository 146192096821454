import * as React from 'react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import './App.css';
import Navigation from './pages/Navigation';
import Werke from './pages/Werke';
import Aktuelles from './pages/Aktuelles';
import Header from './parts/Header';
import Footer from './parts/Footer';
import Vita from "./pages/Vita";
import Startseite from "./pages/Startseite";
import Buecher from "./pages/Buecher";
import ImpressumKontakt from "./pages/ImpressumKontakt";
import Datenschutz from "./pages/Datenschutz";


const App = () => {
    localStorage.clear();
    return (
        <>
            <Header/>
            <Router>
                <Navigation/>
                <Switch>
                    <Route path="/werke/">
                        <Werke/>
                    </Route>
                    <Route path="/buecher/">
                        <Buecher/>
                    </Route>
                    <Route path="/vita">
                        <Vita />
                    </Route>
                    <Route path="/aktuelles">
                        <Aktuelles/>
                    </Route>
                    <Route path="/impressum-kontakt">
                        <ImpressumKontakt />
                    </Route>
                    <Route path="/datenschutz">
                        <Datenschutz />
                    </Route>
                    <Route path="/">
                        <Startseite />
                    </Route>
                </Switch>
                <Footer/>
            </Router>
        </>
    )
        ;
};
export default App;
