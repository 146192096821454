import * as React from 'react';
import DocumentMeta from 'react-document-meta';

const Header = () => {
    const meta = {
        title: 'Michael Merkel',
        description: 'Künstler',
        canonical: 'michael-merkel.eu',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'michael merkel, dresden, künstler'
            }
        }

    };


    let favicon = document.getElementById("favicon");


    return (
        <DocumentMeta {...meta}>

        </DocumentMeta>
    );
}


export default Header;
