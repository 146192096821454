import * as React from "react";

const Buch = (props) => {
    if (props.url !== '') {

        return (
            <>
                <img className="buch-single-gross" data-bs-toggle="modal" data-bs-target="#exampleModal" src={props.url} alt=""/>

                <div class="modal  fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-body">
                                <img className="" src={props.bildmax} data-bs-dismiss="modal" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }else{
        return (
            <>
                <img className="buch-single-gross" data-bs-toggle="modal" data-bs-target="#exampleModal"  src={props.fallback} alt=""/>
                <div className="modal  fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <img className="" src={props.fallback} data-bs-dismiss="modal" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Buch;
