import * as React from "react";
import parse from "html-react-parser";
import nl2br from "../lib/nl2br";

const Werk = (props) => {
    let werk = props.werk;
    let foto;
    try{
        return (
            <div id="page" className="werkesingle">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-10 offset-1 text-center">
                            <h1 class="werktitel">
                                {parse(werk.title.rendered)}
                            </h1>
                            <p className="werkdesc">
                            {parse(werk.content.rendered)}
                            </p>
                            {werk.acf.fotos.map(function (item) {
                                foto = item.sizes.singleansicht;

                                return (
                                    <>
                                        <div className="container-fluid mt-5 mb-3">

                                            <div className="row text-center align-self-center justify-content-center">
                                                <div className="col-auto text-center">
                                                    <img className="werke-single-foto" src={foto}  alt="" /><br/>
                                                    <div className="bildunterschrift mx-auto text-end">{parse(item.caption)}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-5">
                                            <strong>
                                                {parse(item.title)}
                                            </strong><br/>
                                            {parse(nl2br(item.description))}<br/>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }catch{
        return (
            <></>
        );
    }

};

export default Werk;
