import * as React from 'react';
import axios from 'axios';
import {ImageLoader} from "../lib/loader";

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'DATA_FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case 'DATA_FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'DATA_FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case 'REMOVE_DATA':
            return {
                ...state,
                data: state.data.filter(
                    (story) => action.payload.objectID !== story.objectID
                ),
            };
        default:
            throw new Error();
    }
};

const FeaturedImageTwo = (url) => {
    const [data, dispatchData] = React.useReducer(
        dataReducer,
        {data: [], isLoading: false, isError: false}
    );

    // console.log(url);

    const handleFetchData = React.useCallback(() => {
        dispatchData({type: 'DATA_FETCH_INIT'});

        axios
            .get(url.url)
            .then((result) => {
                dispatchData({
                    type: 'DATA_FETCH_SUCCESS',
                    payload: result.data,
                });
            })
            .catch(() =>
                dispatchData({type: 'DATA_FETCH_FAILURE'})
            );
    }, [url]);

    React.useEffect(() => {
        handleFetchData();
    }, [handleFetchData]);

    let id = '';
    let mediaurl = '';
    let alt = '';
    let title = '';
    let description = '';

    if (data.isError === false && data.isLoading === false) {
        try {
            // console.log(data);
            id = data.data.id;
            mediaurl = data.data.media_details.sizes.start.source_url;
            alt = data.data.alt_text;
            title = data.data.title.rendered;
            description = data.data.caption.rendered;
        } catch (err) {}
    }

    return (
        <>
                {data.isLoading ? (
                    <>
                        <ImageLoader />
                    </>
                ) : (
                    <>
                        <img className="image-aktuelles" data-desc={description} data-media-id={id} src={mediaurl} alt={alt} title={title}/>
                    </>
                )}

        </>
    );
};

export default FeaturedImageTwo;
