import * as React from "react";
import {API_BUCH_POSTID, MEDIA_API_URL} from "../lib/constants";
import axios from "axios";
import Linkmaker from "../lib/linkmaker";
import FeaturedImageTwo from "../components/Featured_ImageTwo";


const dataReducer = (state, action) => {
    switch (action.type) {
        case 'DATA_FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case 'DATA_FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'DATA_FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            throw new Error();
    }
};


const StartkachelBuch = (props) => {
    // console.log(props.kachel);
    const [url] = React.useState(API_BUCH_POSTID);

    let localurl = url + props.kachel;
    // console.log(localurl);
    // let kachel = props.kachel;

    const [data, dispatchData] = React.useReducer(
        dataReducer,
        {data: [], isLoading: false, isError: false}
    );

    const handleFetchData = React.useCallback(() => {
        if (localStorage.getItem('start' + props.kachel) != null) {
            dispatchData({
                type: 'DATA_FETCH_SUCCESS',
                payload: JSON.parse(localStorage.getItem('start' + props.kachel)),
            });
        } else {
            dispatchData({type: 'DATA_FETCH_INIT'});
            axios
                .get(localurl)
                .then((result) => {
                    dispatchData({
                        type: 'DATA_FETCH_SUCCESS',
                        payload: result.data,
                    });
                    localStorage.setItem('start' + props.kachel, JSON.stringify(result.data));

                })
                .catch((err) =>
                    dispatchData({type: 'DATA_FETCH_FAILURE'})
                );
        }

    }, [url]);

    React.useEffect(() => {
        handleFetchData();
    }, [handleFetchData]);

    let myurl = MEDIA_API_URL + '/' + data.data.featured_media;
    try {
        return (
            <div className="col m-0 p-2">
                <a href={Linkmaker(data.data.link)}>
                    <FeaturedImageTwo url={myurl}/>
                </a>
            </div>
        )
    } catch {
        return (
            <></>
        )
    }
};


export default StartkachelBuch;
