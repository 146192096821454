import * as React from "react";
import {Link} from "react-router-dom";
import FeaturedImage from "../components/Featured_Image";
import {MEDIA_API_URL} from "../lib/constants";

const Buecherliste = (props) => {
    return (
        <div id="page" className="werkearchiv">
            <div className="container-fluid">
                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-xl-3">
                    {props.list.map(function (item) {
                        let myurl = MEDIA_API_URL + '/' + item.featured_media;
                        return (
                            <div className="col d-flex justify-content-center">
                                <div className="container-fluid m-3">
                                    <div className="row">
                                        <div className="col d-flex justify-content-center">
                                            <Link
                                                to={item.link.replace('https://2021.michael-merkel.eu', '')}>
                                                <FeaturedImage url={myurl}/>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col d-flex justify-content-center m-3">
                                            <Link
                                                to={item.link.replace('https://2021.michael-merkel.eu', '')}>
                                                {item.title.rendered}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default Buecherliste;
