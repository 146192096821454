import * as React from 'react';
import axios from 'axios';
import {API_IMPRESSUM} from "../lib/constants";
import parse from 'html-react-parser';

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'DATA_FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case 'DATA_FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'DATA_FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            throw new Error();
    }
};

const ImpressumKontakt = () => {
    const [url] = React.useState(API_IMPRESSUM);
    const [data, dispatchData] = React.useReducer(
        dataReducer,
        {data: [], isLoading: false, isError: false}
    );

    const handleFetchData = React.useCallback(() => {
        if (localStorage.getItem('impressum') != null) {
            dispatchData({
                type: 'DATA_FETCH_SUCCESS',
                payload: JSON.parse(localStorage.getItem('impressum')),
            });
        } else {
            dispatchData({type: 'DATA_FETCH_INIT'});
            axios
                .get(url)
                .then((result) => {
                    dispatchData({
                        type: 'DATA_FETCH_SUCCESS',
                        payload: result.data,
                    });
                    localStorage.setItem('impressum', JSON.stringify(result.data));

                })
                .catch(() =>
                    dispatchData({type: 'DATA_FETCH_FAILURE'})
                );
        }

    }, [url]);

    React.useEffect(() => {
        handleFetchData();
    }, [handleFetchData]);
    try {

        // console.log(data);
        return (
            <div id="page">
                <div className="container-fluid">

                    <div className="row ">
                        <div className="col-10 offset-1 col-md-10 offset-md-1 col-xl-6 offset-xl-3 ">

                            <h1>
                                {parse(data.data.title.rendered)}
                            </h1>
                            <p>
                                {parse(data.data.content.rendered)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        );
    } catch {
        return (<></>);
    }
}
export default ImpressumKontakt;

