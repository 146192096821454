import * as React from 'react';
import axios from 'axios';
import * as Constants from '../lib/constants';
import * as Loader from '../lib/loader';
import {Link, NavLink, Switch} from "react-router-dom";
import logo from "../assets/images/michael_merkel_logo.png";

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'DATA_FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case 'DATA_FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'DATA_FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case 'REMOVE_DATA':
            return {
                ...state,
                data: state.data.filter(
                    (story) => action.payload.objectID !== story.objectID
                ),
            };
        default:
            throw new Error();
    }
};

const Navigation = () => {
    const [url] = React.useState(
        `${Constants.API_MAIN_MENU}`
    );

    const [data, dispatchData] = React.useReducer(
        dataReducer,
        {data: [], isLoading: false, isError: false}
    );

    const handleFetchData = React.useCallback(() => {
        if (localStorage.getItem('navigation') != null) {
            dispatchData({
                type: 'DATA_FETCH_SUCCESS',
                payload: JSON.parse(localStorage.getItem('navigation')),
            });
        } else {

            dispatchData({type: 'DATA_FETCH_INIT'});
            axios
                .get(url)
                .then((result) => {
                    dispatchData({
                        type: 'DATA_FETCH_SUCCESS',
                        payload: result.data,
                    });
                    localStorage.setItem('navigation', JSON.stringify(result.data));
                })
                .catch(() =>
                    dispatchData({type: 'DATA_FETCH_FAILURE'})
                );
        }
    }, [url]);

    React.useEffect(() => {
        handleFetchData();
    }, [handleFetchData]);

    return (
        <div id="navbar" className="bg-white">
            {data.isError && <p>Something went wrong ...</p>}
            {data.isLoading ? (
                <Loader.MyLoader/>
            ) : (
                <List list={data.data}/>
            )}
        </div>
    );
};

const List = ({list}) => {
    return (
        <Switch>
            <nav className="navbar navbar-expand-md navbar-light bg-white">
                <div className="container-fluid">
                    <Link to="/">
                        <img id="logo" src={logo} alt=""/>
                    </Link>
                    <Link to="/" className="navbar-brand">
                        Michael Merkel
                    </Link>
                    <div className="desktopmenu navbar-collapse justify-content-end d-none d-md-block" id="navbarNavAltMarku">
                        <div className="navbar-nav">
                            {list.map((item) => (
                                <Item
                                    key={item.objectID}
                                    item={item}
                                />
                            ))}
                        </div>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
                            aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="mobilemenu collapse navbar-collapse justify-content-end mt-5 pl-5" id="navbarNavAltMarkup">
                    <div className="navbar-nav d-md-none pl-5">
                        {list.map((item) => (
                            <Item
                                key={item.objectID}
                                item={item}
                            />
                        ))}
                    </div>
                </div>
            </nav>
        </Switch>
    );
};

const Item = ({item}) => {
    return (
        <NavLink to={item.url.replace('https://2021.michael-merkel.eu', '')} className="nav-link" activeClassName="active">
            {item.title}
        </NavLink>
    );
};

export default Navigation;
