import * as React from 'react';
import axios from 'axios';
import * as Constants from '../lib/constants';
import * as Loader from '../lib/loader';
import {NavLink, Switch} from "react-router-dom";
import parse from 'html-react-parser';
import insta from "../assets/images/instagram-brands.svg";
import mail from "../assets/images/envelope-solid.svg";


const dataReducer = (state, action) => {
    switch (action.type) {
        case 'DATA_FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case 'DATA_FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'DATA_FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case 'REMOVE_DATA':
            return {
                ...state,
                data: state.data.filter(
                    (story) => action.payload.objectID !== story.objectID
                ),
            };
        default:
            throw new Error();
    }
};

const Footer = () => {
    const [url] = React.useState(
        `${Constants.API_FOOTER_MENU}`
    );

    const [data, dispatchData] = React.useReducer(
        dataReducer,
        {data: [], isLoading: false, isError: false}
    );

    const handleFetchData = React.useCallback(() => {
        if (localStorage.getItem('footer') != null) {
            dispatchData({
                type: 'DATA_FETCH_SUCCESS',
                payload: JSON.parse(localStorage.getItem('footer')),
            });
        } else {


            dispatchData({type: 'DATA_FETCH_INIT'});

            axios
                .get(url)
                .then((result) => {
                    dispatchData({
                        type: 'DATA_FETCH_SUCCESS',
                        payload: result.data,
                    });
                    localStorage.setItem('footer', JSON.stringify(result.data));
                })
                .catch(() =>
                    dispatchData({type: 'DATA_FETCH_FAILURE'})
                );
        }
    }, [url]);

    React.useEffect(() => {
        handleFetchData();
    }, [handleFetchData]);

    return (
        <>
            {data.isError && <p>Something went wrong ...</p>}
            {data.isLoading ? (
                <Loader.MyLoader/>
            ) : (
                <List list={data.data}/>
            )}
        </>
    );
};

const List = ({list}) => {
    return (
        <Switch>
            <div id="footer" className="container-fluid ">

                <div className="row">
                    <div className="col-6 offset-3  d-flex justify-content-center mt-5 mb-4">
                        <a href="https://www.instagram.com/michael.merkel.art/" className="mx-3">
                            <img
                                id="instalink"
                                src={insta}
                                alt=""
                            />
                        </a>
                        <a href="mailto:info@michael-merkel.eu" className="mx-3">
                            <img
                                id="maillink"
                                src={mail}
                                alt=""
                            />
                        </a>

                    </div>
                    <div className="col-6 offset-3  d-flex justify-content-center text-center my-4">
                        <ul>
                            <li>
                                MICHAEL MERKEL &copy; 2021
                            </li>
                            {list.map((item) => (
                                <Item
                                    key={item.objectID}
                                    item={item}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Switch>
    );
};

const Item = ({item}) => {
    return (
        <li className="d-inline">
            <NavLink to={item.url.replace('https://2021.michael-merkel.eu', '')} activeClassName="active">
                {parse(item.title)}
            </NavLink>
        </li>
    );
};

export default Footer;
