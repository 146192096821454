import * as React from 'react';
import {useLocation, useRouteMatch} from "react-router-dom";
import {API_BUECHER} from "../lib/constants";
import axios from "axios";
import Buecherliste from "../components_low/Buecherliste";
import Buch from "../components_low/Buch";

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'DATA_FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case 'DATA_FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'DATA_FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            throw new Error();
    }
};

const Buecher = () => {
    const [url] = React.useState(API_BUECHER);
    const [data, dispatchData] = React.useReducer(
        dataReducer,
        {data: [], isLoading: false, isError: false}
    );

    const handleFetchData = React.useCallback(() => {
        if (localStorage.getItem('buecher') != null) {
            dispatchData({
                type: 'DATA_FETCH_SUCCESS',
                payload: JSON.parse(localStorage.getItem('buecher')),
            });
        } else {
            dispatchData({type: 'DATA_FETCH_INIT'});
            axios
                .get(url)
                .then((result) => {
                    dispatchData({
                        type: 'DATA_FETCH_SUCCESS',
                        payload: result.data,
                    });
                    localStorage.setItem('buecher', JSON.stringify(result.data));

                })
                .catch(() =>
                    dispatchData({type: 'DATA_FETCH_FAILURE'})
                );
        }

    }, [url]);

    React.useEffect(() => {
        handleFetchData();
    }, [handleFetchData]);

    let slug = useLocation().pathname;

    if (useRouteMatch().isExact) {
        return (
            <Buecherliste list={data.data}/>
        );
    } else {
        let buch;
        data.data.map(function (item) {
            if (slug === '/buecher/' + item.slug + '/') {
                buch = item;
            }
        });

        return (
            <Buch buch={buch} />
        )
    }
}
export default Buecher;
