import ContentLoader from "react-content-loader";

export const MyLoader = (props) => (
    <ContentLoader
        speed={1}
        width={500}
        height={50}
        viewBox="0 0 500 50"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="20" y="20" rx="3" ry="3" width="100%" height="20"/>
    </ContentLoader>
)

export const ImageLoader = (props) => (
    <ContentLoader
        speed={1}
        width={300}
        height={300}
        viewBox="0 0 300 300"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="20" y="20" rx="3" ry="3" width="300" height="300"/>
    </ContentLoader>
)
