export const POSTS_API_URL = "https://2021.michael-merkel.eu/wp-json/wp/v2/posts?per_page=100"
export const AUTHORS_API_URL = "https://2021.michael-merkel.eu/wp-json/wp/v2/users?per_page=100"
export const MEDIA_API_URL = "https://2021.michael-merkel.eu/wp-json/wp/v2/media"

export const API_WERKE_SINGLE = "https://2021.michael-merkel.eu/wp-json/wp/v2/werke/?slug="

export const API_AKTUELLES = "https://2021.michael-merkel.eu/wp-json/wp/v2/posts?per_page=100"
export const API_MAIN_MENU = "https://2021.michael-merkel.eu/wp-json/wp/v2/menu"
export const API_FOOTER_MENU = "https://2021.michael-merkel.eu/wp-json/wp/v2/menufooter"


export const API_POSTS = "https://2021.michael-merkel.eu/wp-json/wp/v2/posts?per_page=100"
export const API_WERKE = "https://2021.michael-merkel.eu/wp-json/wp/v2/werke?per_page=100"
export const API_MEDIA_URL = "https://2021.michael-merkel.eu/wp-json/wp/v2/media"
export const API_VITA = "https://2021.michael-merkel.eu/wp-json/wp/v2/pages/18"
export const API_START = "https://2021.michael-merkel.eu/wp-json/wp/v2/pages/594"
export const API_BUECHER = "https://2021.michael-merkel.eu/wp-json/wp/v2/buecher?per_page=100&_embed"
export const API_WERKE_POSTID = "https://2021.michael-merkel.eu/wp-json/wp/v2/werke/"
export const API_IMPRESSUM = "https://2021.michael-merkel.eu/wp-json/wp/v2/pages/86"
export const API_Datenschutz = "https://2021.michael-merkel.eu/wp-json/wp/v2/pages/88"
export const API_BUCH_POSTID = "https://2021.michael-merkel.eu/wp-json/wp/v2/buecher/"

// export const ENV = 'DEV'
// export const ENV = 'PRODUCTION'


