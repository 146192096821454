import * as React from 'react';
import axios from 'axios';
import {API_VITA} from "../lib/constants";
import {ImageLoader} from "../lib/loader";
import Vitalow from "../components_low/Vita";

const dataReducer = (state, action) => {
    switch (action.type) {
        case 'DATA_FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case 'DATA_FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'DATA_FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            throw new Error();
    }
};

const Vita = () => {
    const [url] = React.useState(API_VITA);
    const [data, dispatchData] = React.useReducer(
        dataReducer,
        {data: [], isLoading: false, isError: false}
    );

    const handleFetchData = React.useCallback(() => {
        if(localStorage.getItem('vita')!=null){
            dispatchData({
                type: 'DATA_FETCH_SUCCESS',
                payload: JSON.parse(localStorage.getItem('vita')),
            });
        } else {
            dispatchData({type: 'DATA_FETCH_INIT'});
            axios
                .get(url)
                .then((result) => {
                    dispatchData({
                        type: 'DATA_FETCH_SUCCESS',
                        payload: result.data,
                    });
                    localStorage.setItem('vita', JSON.stringify(result.data));
                })
                .catch(() =>
                    dispatchData({type: 'DATA_FETCH_FAILURE'})
                );
        }

    }, [url]);

    React.useEffect(() => {
        handleFetchData();
    }, [handleFetchData]);

    return (
        <>
            {data.isError && <p>Es ist ein Fehler aufgetreten ...</p>}
            {data.isLoading ? (
                <ImageLoader />
            ) : (
                <Vitalow content={data.data}/>
            )}
        </>
    );
}
export default Vita;

